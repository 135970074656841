import React from 'react'
// import { TextGradient } from '../../_text/_text_gradient'
import CalorexLogo from '../../../components/svg/commons/calorex_logo'

function ResultHeader( { slug, title, name, colorStart, colorEnd } ) {
    return (
        <div className="result__header">
                <div className="result_header__logocontainer"><CalorexLogo/></div>
                <p className='result__header_title'>Tu calentador ideal</p>
                <p className='result__intro'>Te recomendamos los siguientes modelos:</p>
                
                                
        </div>
    )
}

export default ResultHeader
