import React,{useState,useEffect} from 'react'
import Slider from 'rc-slider';
import Shower from '../svg/tucalentador/shower'
import Sink from '../svg/tucalentador/sink'
// import 'rc-slider/assets/index.css';


function SliderSelector( props ) {
    const [value, setValue] = useState(1)
    
    const [valueCopy, setValueCopy] = useState(1)
    const [min, setMin] = useState()
    const [max, setMax] = useState()
    const [step, setStep] = useState()

    // 2.0, 4.0, 6.0, 8.0, 10.0 son 1/2
    const marks = {
        1.0 : <span className='line__color line__integer'></span>,
        2.0 : <span className='line__color'></span>,
        3.0 : <span className='line__color line__integer'></span>,
        4.0 : <span className='line__color'></span>,
        5.0 : <span className='line__color line__integer'></span>,
        6.0 : <span className='line__color'></span>,
        7.0 : <span className='line__color line__integer'></span>,
        8.0 : <span className='line__color'></span>,
        9.0 : <span className='line__color line__integer'></span>,
        10.0 : <span className='line__color'></span>,
        11.0 : <span className='line__color line__integer'></span>,
      };

      useEffect(() =>{
        setMin(props.min);
        setMax(props.max);
        setStep(1)
    },[props] )


    useEffect(() => {

        function modifyValue(value){
            props.setValue(value);
            setValueCopy(value);
        }

        if(value === 2 || value === 3){
            modifyValue(2);
        }else if( value === 4 || value === 5){
            modifyValue(3);
        }else if( value === 6 || value === 7){
            modifyValue(4);
        }else if( value === 8 || value === 9){
            modifyValue(5);
        }else if( value === 10 || value === 11){
            modifyValue(6);
        }else{
            modifyValue(value);
        }

    }, [value, props])


    return (
        <div className='option__slider--content'>
            <div className="slider__header--title">
                <h1>¿Cuántos servicios utilizas?</h1>
            </div>
            <div className='slider__container' >
                <div className="slider__selection">
                    <span>{ value % 2 === 0 ? (valueCopy - 1) + ' ½' : valueCopy } {value>1 ? "Servicios" : "Servicio"} </span>
                </div>
                <Slider value={value} marks={marks} min={min} max={max} step={step} onChange={setValue} />
                <div className="slider__header--info">
                    <div className="slider__header--info__element">
                        <Shower className="slider__header--shower slider__header--svg"/>
                        <span className="slider__header--info__span">= 1 servicio</span>
                    </div>
                    <div className="slider__header--info__element">
                        <Sink className="slider__header--sink slider__header--svg" /> 
                        <span className="slider__header--info__span">= ½ servicio</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SliderSelector