import React,{useState,useEffect} from 'react'
import Slider  from 'rc-slider';
// import 'rc-slider/assets/index.css';


function SliderPeople( props ) {
   
    const [value, setValue] = useState(1)
    const [min, setMin] = useState()
    const [max, setMax] = useState()
    const [step, setStep] = useState()
    const [caption,setCaption] = useState('persona')


    const marks = {
        1.0 : <span className='line__color'></span>,
        2.0 : <span className='line__color'></span>,
        3.0 : <span className='line__color'></span>,
        4.0 : <span className='line__color'></span>,
        5.0 : <span className='line__color'></span>,
        6.0 : <span className='line__color'></span>,
      };
      
      useEffect(() =>{
          setMin(props.min);
          setMax(props.max);
          setStep(1)
      },[props] )


    useEffect(() => {
        // log(value)
        function modifyValue(value){
            props.setValue(value);
        }

        modifyValue(value)

        if(value===1)
        {
            setCaption('persona');
        }
        else{
            setCaption('personas');
        }

    }, [value, props])

    return (
        <div className='option__slider--content'>
            <div className="slider__header--title">
                <h1>¿Cuántas personas van a disfrutar del agua caliente?</h1>
            </div>
            <div className='slider__container' >
                <div className="slider__selection">
                    <span>{ value } {caption} </span>
                </div>
                <Slider value={value} marks={marks} min={min} max={max} step={step} onChange={setValue} />

            </div>
        </div>
    )
}

export default SliderPeople