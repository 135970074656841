import React from 'react'

const Building = props => (
  <svg viewBox="0 0 60 65" {...props}>
    <path d="M59.4 21.1L30.9.3c-.5-.4-1.3-.4-1.8 0L.6 21.1c-.7.5-.8 1.5-.3 2.2.5.7 1.5.8 2.2.3L30 3.5l27.5 20.1c.3.2.6.3.9.3.5 0 1-.2 1.3-.6.5-.7.4-1.7-.3-2.2zM51.8 25c-.9 0-1.6.7-1.6 1.6V62H9.7V26.5c0-.8-.7-1.5-1.5-1.5s-1.6.7-1.6 1.6v36.9c0 .9.7 1.6 1.6 1.6h43.7c.9 0 1.6-.7 1.6-1.6v-37c-.1-.8-.8-1.5-1.7-1.5z" />
    <path d="M43.6 37.4v5.4h-8v-5.4h8m1.2-2.8H34.3c-.9 0-1.6.7-1.6 1.6V44c0 .9.7 1.6 1.6 1.6h10.4c.9 0 1.6-.7 1.6-1.6v-7.8c0-.9-.7-1.6-1.5-1.6zm-1.2-10.2v5.4h-8v-5.4h8m1.2-2.8H34.3c-.9 0-1.6.7-1.6 1.6V31c0 .9.7 1.6 1.6 1.6h10.4c.9 0 1.6-.7 1.6-1.6v-7.8c0-.9-.7-1.6-1.5-1.6zM24.5 37.4v5.4h-8v-5.4h8m1.2-2.8H15.2c-.9 0-1.6.7-1.6 1.6V44c0 .9.7 1.6 1.6 1.6h10.4c.9 0 1.6-.7 1.6-1.6v-7.8c0-.9-.7-1.6-1.5-1.6zm-1.2-10.2v5.4h-8v-5.4h8m1.2-2.8H15.2c-.9 0-1.6.7-1.6 1.6V31c0 .9.7 1.6 1.6 1.6h10.4c.9 0 1.6-.7 1.6-1.6v-7.8c0-.9-.7-1.6-1.5-1.6zm17.9 28.7v5.4h-8v-5.4h8m1.2-2.8H34.3c-.9 0-1.6.7-1.6 1.6v7.8c0 .9.7 1.6 1.6 1.6h10.4c.9 0 1.6-.7 1.6-1.6v-7.8c0-.9-.7-1.6-1.5-1.6zm-20.3 2.8v5.4h-8v-5.4h8m1.2-2.8H15.2c-.9 0-1.6.7-1.6 1.6v7.8c0 .9.7 1.6 1.6 1.6h10.4c.9 0 1.6-.7 1.6-1.6v-7.8c0-.9-.7-1.6-1.5-1.6z" />
    <path d="M24.5 37.3v5.4h-8v-5.4h8m1.2-2.7H15.2c-.9 0-1.6.7-1.6 1.6V44c0 .9.7 1.6 1.6 1.6h10.4c.9 0 1.6-.7 1.6-1.6v-7.8c0-.9-.7-1.6-1.5-1.6z" />
  </svg>
)

export default Building