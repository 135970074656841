import { useStaticQuery, graphql } from "gatsby"
export const usePrismVersionResult = () => {
const prismic = useStaticQuery(
    graphql`
        query {
            allPrismicVersion {
                edges {
                    node {
                        uid
                        data {
                            title{
                                text
                            }
                            image{
                                url
                                alt
                            }
                            
                            model {
                                slug
                                document {
                                    uid
                                    data {
                                        claim {
                                            text
                                        }
                                        model_name {
                                            text
                                            html
                                        }
                                        color_start {
                                            text
                                        }
                                        color_end {
                                            text
                                        }
                                        ideal_copy{
                                            text
                                        }
                                        ideal_list{
                                            text
                                        }
                                        body {
                                            __typename
                                            ... on PrismicModelBodyVentajasResultado{
                                                items{
                                                  advantage{
                                                    html
                                                    text
                                                  }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            result_keys {
                                result_id
                            }
                            result_image {
                                url
                                mb {
                                    url
                                }
                                tb {
                                    url
                                }
                                lg {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    `
  );
// console.log('allPrismicBlog->',prismic.allPrismicBlog);
return prismic.allPrismicVersion

  
}
