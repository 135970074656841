import React,{useState,useEffect,useRef} from 'react'
import Layout from "../components/layout"
import '../styles/pages/help/help.scss'
// import CircularSelector from '../components/help/circular_selector'
// import TextOption from '../components/help/text_option'
import CardOption from '../components/help/card_option'
import SliderSelector from '../components/help/slider_selector'
import SliderPeople from '../components/help/slider_people'
import LoadingScreen from '../components/help/loading'
import PaginatorNumber from '../components/help/paginator_number'

import Result from '../components/help/result'
import CssClassManager from '../components/_helpers/_css_class_manager'
// import {helpInfo} from '../hooks/help_hook'
import {ButtonArrow,TYPES} from '../components/buttons/_btn_arrow'

function Help() {
	// const [valor, setvalor] = useState([])
	const [option, setOption] = useState(1)
	const [loading, setLoading] = useState(false)
	const [result, setResult] = useState(false)
	const [total, setTotal] = useState(4)
	const [value, setValue] = useState(null)
	
	// const [firstOption, setFirst] = useState('')
	
	const [sequence, setSequence] = useState([])
	const title = useRef(null)	
	const paginator = useRef(null)
	let cssClassManager= new CssClassManager();
	
	const [hometype, setHomeType] = useState(1)
	const [technologyType, setTechnologyType] = useState(1)
	const [people, setPeople] = useState(1)
	const [services, setServices] = useState(1)	
	

	useEffect(() => {
		document.getElementsByTagName('footer')[0].style.display = 'none'
		return () => {
			document.getElementsByTagName('footer')[0].style.display = 'block'
		};
	},)
	

	useEffect(() => {

		if(sequence[0] != null ){
			// setFirst(sequence[0])
		  	// console.log('first ',firstOption);
		}

		// console.log('sequence ',sequence);
		if(window)
		{
			window.scrollTo(0,0);
		}
		
		if(sequence === []) return(null);
		
		sequence.map((element) => {
			if(element === 'a' && element === 'b'){
				return setOption(6)
			}else return(null)
		})
		
	}, [sequence])
	function backToHelp(){
		resetForm();
		
		setResult(false);
	}
	function resetForm(){
		setOption(1);
		setTotal(4)
		// setFirst('')
		
		setSequence([]);

		setServices(1)
		setTechnologyType(1)
		setPeople(1)
		
	}
	function handleLoadingScreen() {
		if(!loading){
			title.current.style.display = 'none';
			paginator.current.style.display = 'none';
			setLoading(true)
			setTimeout(() => {
				setOption(7)
				setLoading(false)
				setResult(true)
				
				
				// console.log('se deactivo el loading', loading)
				// console.log('se encontro resultado ',result)
			}, 5000);
			// }, 50);
		}
	}

	const firstObject = [
		{image:'House', bodyTitle:'Casa', bodyDesc:'Necesito agua caliente para las manos, duchas o usos en cocina', selected:'house' },
		{image:'Building', bodyTitle:'Departamento', bodyDesc:'Necesito agua caliente para las manos, duchas o usos en cocina', selected:'department'},
		// {image:'Bussiness', bodyTitle:'Negocio', bodyDesc:'Necesito agua caliente para las manos, duchas o usos en cocina', selected:'n' }
	]

	const secondObject = [
		{image:'Flame', bodyTitle:'Gas',bodyDesc:'', selected:'gas' },
		{image:'Thunder', bodyTitle:'Eléctrica',bodyDesc:'', selected:'electric'},
		{image:'Sun', bodyTitle:'Solar',bodyDesc:'', selected:'sun' }
	]

	function changeHomeType(_type){
		setHomeType(_type);
		handleNext();
	}

	function changeTechnologyType(_type){
		setTechnologyType(_type);
		handleNext();
	}

	

	function getOptionSelector(option) {
		
		switch (option) {
			case 1:
				return <CardOption handleClickSequence={changeHomeType} handleNext={handleNext} numQuest={1} question={'¿Vives en casa o departamento?'} object={firstObject} />
				
			case 2:				
				return <CardOption handleClickSequence={changeTechnologyType} handleNext={handleNext} numQuest={2} question={'¿Qué tipo de energía necesitas?'} object={secondObject} class_center='option__content--center'/>
				
			case 3:
				// document.getElementsByClassName('nav__next')[0].className = 'nav__next'
				cssClassManager.remove_class(document.getElementsByClassName('nav__next')[0],'disable');
				return <SliderSelector setValue={setServices} min={1} max={11} ranges={[{maxRange:6,option:'1'}]} />
				
			case 4:
				return <SliderPeople setValue={setPeople} min={1} max={6} ranges={[{maxRange:6,option:'1'}]} />
				// return <TextOption handleClickSequence={handleClickSequence} question={'¿Cuántas personas van a disfrutar del agua caliente'} items={[{select:'1-2 personas',option:'a'},{select:'3+ personas',option:'b'}]} />
				
			case 5:
				handleLoadingScreen()
				break;			
			default:
				// console.log('se produjo un error')
				break;
		}
	}

	function handleNext(){
		// console.log('entra al handle Next');
        if(option <= total){
			setOption(option+1)
		}

		if(value != null ){
			setSequence([...sequence,value])
			setValue(null)
			// console.log('sequence next ',sequence);
		}
	}
	
	function handlePrev(){
        if(option > 1){
			
			setOption(option - 1)
			// if(option != 3){
			setValue(null)
			let sequence2 = sequence;
			sequence2.pop()
			setSequence(sequence2)	
			// }
		}
	}
	
	function getHelp() {
		return <div className={`help__container help__container--step${option}`} >
					<div className="header__title" ref={title}>
						<h1>Encuentra tu calentador</h1>
					</div>
					
					{ getOptionSelector(option) }
					{loading ? <LoadingScreen /> : null}
					<div className="help__navigation" ref={paginator} >
						<div className="nav__container">
							
							<ButtonArrow _className={`nav__prev ${option <= 1 || option > 4 ? 'disable' : ''}`}  _text='Anterior' _onClick={handlePrev} section={`help__pagination_back${option}`} _position='before' _type={TYPES.PREV} />
							
							<ButtonArrow _className={`nav__next ${option <= 2 || option > 4 ? 'disable' : ''}`} _text='Siguiente' _onClick={handleNext} section={`help__pagination_next${option}`} />
							
						</div>
						<div className="help__pagination">
							<PaginatorNumber active={option} total={total} />
						</div>
					</div>
				</div> 
	}

    return (
        <>
			<Layout>
				{ result ? <Result hometype={hometype} technologyType={technologyType} people={people} services={services}   _backToHelp={backToHelp}/> : getHelp() }
			</Layout>
        </>
    )
}

export default Help
