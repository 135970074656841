import React from 'react'
import {TextGradient} from '../_text/_text_gradient'
import Bubbles from '../_animations/bubbles'

function Loading() {
    const title = 'Estamos buscando el calentador ideal para tus necesidades...'
    return (
        <div className="loading__page">
            <div className="loading__animation">
                <Bubbles />
            </div>
            <div className="header__loading">
                <TextGradient _text={title} _className='loading__screen--title' _color_start='#ba0c2f' _color_end='#d90b2d' _deg={180} />    
            </div>
        </div>
    )
}

export default Loading
