import React from 'react'

const Check = props => (
  <svg width={9.282} height={10.188} {...props}>
    <path
      data-name="check"
      d="M1.009 5.5l2.75 3.018L8.283.994"
      fill={"none"}
      stroke={props.stroke}
      strokeLinecap="round"
      strokeWidth={2}
    />
  </svg>
)

export default Check