import * as React from "react"

function Shower(props) {
  return (
    <svg viewBox="0 0 26 26" {...props}>
      <path d="M18.1 7.9v-.8c0-2.1-1.7-3.8-3.8-3.8h-.5V.5h-1.6v2.9h-.5C9.6 3.4 8 5.1 8 7.1v.7c-2.9.7-4.9 3.3-4.9 6.2 0 .4.4.8.8.8h18.3c.4 0 .8-.4.8-.8-.1-2.8-2.1-5.4-4.9-6.1zM11.7 5h2.6c1.2 0 2.2 1 2.2 2.2v.5H9.6v-.6c0-1.2.9-2.1 2.1-2.1zm-7 8.3c.4-2.3 2.4-4 4.7-4h7.1c2.3 0 4.3 1.7 4.7 4H4.7zm9.1 6v-2.1c0-.4-.4-.8-.8-.8s-.8.4-.8.8v2.1c0 .4.4.8.8.8s.8-.4.8-.8zm4.6.8c.4 0 .8-.4.8-.8v-2.1c0-.4-.4-.8-.8-.8s-.8.4-.8.8v2.1c0 .4.4.8.8.8zm-10-.8v-2.1c0-.4-.4-.8-.8-.8s-.8.4-.8.8v2.1c0 .4.4.8.8.8s.8-.4.8-.8zm8.1 5.4v-2.1c0-.4-.4-.8-.8-.8s-.8.4-.8.8v2.1c0 .4.4.8.8.8s.8-.4.8-.8zm-5.4 0v-2.1c0-.4-.4-.8-.8-.8s-.8.4-.8.8v2.1c0 .4.4.8.8.8s.8-.4.8-.8z" />
    </svg>
  )
}

export default Shower
