import * as React from "react"

function Sink(props) {
  return (
    <svg viewBox="0 0 511.999 511.999" {...props}>
      <path d="M364.989 219.984v-30.048h49.748v-30.001h-79.75v60.051H271V61.995c0-17.641 14.353-31.993 31.994-31.993s31.993 14.353 31.993 31.993v37.99h30.001v-37.99C364.989 27.812 337.178 0 302.994 0c-34.185 0-61.995 27.811-61.995 61.995v157.989h-63.987v-60.051h-79.75v30.001h49.748v30.049H0v36.207c0 91.496 74.438 165.933 165.934 165.933h45.039v44.848c0 24.827 20.199 45.026 45.026 45.026s45.026-20.199 45.026-45.026v-44.848h45.039c91.496 0 165.934-74.437 165.934-165.933v-36.207H364.989zm-93.963 246.989c-.001 8.284-6.741 15.025-15.026 15.025-8.284 0-15.025-6.74-15.025-15.025v-44.848h30.05v44.848zm210.973-210.781c0 74.953-60.98 135.932-135.933 135.932H165.934c-74.953 0-135.933-60.979-135.933-135.932v-6.205h451.997v6.205z" />
    </svg>
  )
}

export default Sink
