import React, {useState,useEffect, useRef} from 'react'
import { usePrismVersionResult } from '../../hooks/use_prism_versions-results'
import ResultHeader from './_result/result_header'

// import { Picture } from 'react-responsive-picture';
import Check from '../svg/commons/check'
import { TextGradient } from '../_text/_text_gradient'
import {ButtonArrow,TYPES} from '../../components/buttons/_btn_arrow'

import Slider from 'react-slick'
import CssClassManager from '../../components/_helpers/_css_class_manager'
import PhoneRingning  from '../svg/commons/phone_ringing';

function Result( props ) {

    const resultQuery = usePrismVersionResult();    
    
    const  [versions,setVersions] = useState([]);
    
    const scrollButton = useRef(null)
    let cssClassManager = new CssClassManager()
    let is_listenning = false;
    let resultMatrix = {
        'gas' : {
            'services': {
                '1': {
                    'persons': {
                        '1': ['mg-10','maximus-advance-g-10','maximus-advance-wifi-g-10', 'fortis-g10','poderus-06','poderus-advance-06','plenus-07', 'fortis-06', 'fortis-s-06'],
                        '2': ['mg-15','maximus-advance-g-15','maximus-advance-wifi-g-15', 'fortis-g10', 'poderus-09','poderus-advance-09', 'fortis-paso-09', 'plenus-07', 'fortis-s-09'],
                        '3': ['plenus-07'],
                        '4': ['plenus-07'],
                        '5': ['plenus-07'],
                        '6': ['plenus-07']
                    }
                    
                },
                '2': {
                    'persons': {
                        '1': ['mg-10','maximus-advance-g-10','maximus-advance-wifi-g-10', 'fortis-g15', 'poderus-06','poderus-advance-06', 'fortis-paso-06', 'plenus-07', 'fortis-06', 'fortis-s-06'],
                        '2': ['mg-20','maximus-advance-g-20','maximus-advance-wifi-g-20', 'fortis-g20', 'poderus-11','poderus-advance-11', 'fortis-paso-11', 'plenus-13','plenus-advance-14', 'fortis-s-11'],
                        '3': ['plenus-13','plenus-advance-14'],
                        '4': ['plenus-13','plenus-advance-14'],
                        '5': ['plenus-13','plenus-advance-14'],
                        '6': ['plenus-13','plenus-advance-14']
                    }
                    
                },
                '3': {
                    'persons': {
                        '1': ['mg-10','maximus-advance-g-10','maximus-advance-wifi-g-10', 'fortis-g20', 'poderus-06','poderus-advance-06', 'fortis-paso-06', 'plenus-07', 'fortis-06', 'fortis-s-06'],
                        '2': ['mg-20','maximus-advance-g-20','maximus-advance-wifi-g-20', 'fortis-g20', 'poderus-11','poderus-advance-11', 'fortis-paso-11', 'plenus-13','plenus-advance-14', 'fortis-s-11'],
                        '3': ['mg-30','maximus-advance-g-30','maximus-advance-wifi-g-30', 'poderus-15','plenus-advance-16', 'fortis-g30'],
                        '4': ['plenus-advance-16'],
                        '5': ['plenus-advance-16'],
                        '6': ['plenus-advance-16']
                    }
                },
                '4': {
                    'persons': {
                        '1': ['mg-10','maximus-advance-g-10','maximus-advance-wifi-g-10', 'fortis-g20', 'poderus-06','poderus-advance-06', 'fortis-paso-06', 'plenus-07', 'fortis-06', 'fortis-s-06'],
                        '2': ['mg-20','maximus-advance-g-20','maximus-advance-wifi-g-20', 'fortis-g20', 'poderus-11','poderus-advance-11', 'fortis-paso-11', 'plenus-13','plenus-advance-14', 'fortis-s-11'],
                        '3': ['mg-30','maximus-advance-g-30','maximus-advance-wifi-g-30', 'fortis-g30', 'poderus-15','plenus-advance-16'],
                        '4': ['mg-40','maximus-advance-g-40', 'fortis-g40', 'poderus-20','plenus-advance-24'],
                        '5': ['plenus-advance-24'],
                        '6': ['plenus-advance-24']
                    }
                },'5': {
                    'persons': {
                        '1': ['fortis-g20', 'fortis-paso-06', 'plenus-07', 'fortis-06', 'fortis-s-06'],
                        '2': ['fortis-g20', 'fortis-paso-11', 'plenus-13','plenus-advance-14', 'fortis-s-11'],
                        '3': ['fortis-g30', 'plenus-advance-16'],
                        '4': ['fortis-g40','plenus-advance-24'],
                        '5': ['plenus-advance-28'],
                        '6': ['plenus-advance-28']
                    }
                },
                '6': {
                    'persons': {
                        '1': ['mg-10','maximus-advance-g-10','maximus-advance-wifi-g-10', 'fortis-g20', 'poderus-06', 'poderus-advance-06', 'fortis-paso-06', 'plenus-07', 'fortis-06', 'fortis-s-06'],
                        '2': ['mg-20','maximus-advance-g-20','maximus-advance-wifi-g-20', 'fortis-g20', 'poderus-11', 'poderus-advance-11', 'fortis-paso-11', 'plenus-13', 'plenus-advance-14', 'fortis-s-11'],
                        '3': ['mg-30','maximus-advance-g-30','maximus-advance-wifi-g-30', 'fortis-g30', 'poderus-15', 'plenus-advance-16'],
                        '4': ['mg-40','maximus-advance-g-40','poderus-20','plenus-advance-24', 'fortis-g40'],
                        '5': ['mg-60','maximus-advance-g-60','cd-kt-180-s'],
                        '6': ['mg-60','maximus-advance-g-60','cd-kt-180-s']
                    }
                }
            }
            
        
        },
        'electric' : {
            'services': {
                '1': {
                    'persons': {
                        '1': ['e-10','pro-40-l','levittas-45', 'fortis-e10', 'eleva-9'],
                        '2': ['e-15','pro-50-l','levittas-45', 'fortis-e15', 'eleva-9'],
                        '3': ['e-20','levittas-65','eleva-9'],
                        '4': ['e-30','eleva-9'],
                        '5': ['e-40','eleva-9'],
                        '6': ['e-40','eleva-9'],
                    }
                },
                '2': {
                    'persons': {
                        '1': ['e-10','pro-40-l','levittas-45', 'fortis-e10', 'eleva-9'],
                        '2': ['e-20','levittas-65','fortis-e20', 'eleva-pro-15'],
                        '3': ['e-30','levittas-65','eleva-pro-15',],
                        '4': ['e-40','eleva-pro-15'],
                        '5': ['e-60','eleva-pro-15'],
                        '6': ['e-60','eleva-pro-15'],
                    }
                },
                '3': {
                    'persons': {
                        '1': ['e-10','pro-40-l','levittas-45', 'fortis-e10', 'eleva-9'],
                        '2': ['e-20','levittas-65', 'fortis-e20', 'eleva-pro-15'],
                        '3': ['e-30', 'fortis-e30'],
                        '4': ['e-40'],
                        '5': ['e-60'],
                        '6': ['e-60'],
                    }
                },
                '4': {
                    'persons': {
                        '1': ['e-10','pro-40-l','levittas-45', 'fortis-e10', 'eleva-9'],
                        '2': ['e-20','levittas-65', 'fortis-e20', 'eleva-pro-15'],
                        '3': ['e-30', 'fortis-e30'],
                        '4': ['e-40'],
                        '5': ['e-60'],
                        '6': ['e-60'],
                    }
                },
                '5': {
                    'persons': {
                        '1': ['e-10', 'pro-40-l', 'levittas-45', 'fortis-e10', 'eleva-9'],
                        '2': ['e-20', 'levittas-65', 'fortis-e20', 'eleva-pro-15'],
                        '3': ['e-30', 'fortis-e30'],
                        '4': ['e-40'],
                        '5': ['e-60'],
                        '6': ['e-60'],
                    }
                },
                '6': {
                    'persons': {
                        '1': ['e-10','pro-40-l','levittas-45','fortis-e10', 'eleva-9'],
                        '2': ['e-20', 'levittas-65', 'fortis-e20', 'eleva-pro-15'],
                        '3': ['e-30', 'fortis-e30'],
                        '4': ['e-40'],
                        '5': ['e-60'],
                        '6': ['e-60'],
                    }
                }
                
            }
        },
        'sun' : {
            'services': {
                '1': {
                    'persons': {
                        '1': ['calorex-solar-sl-150'],
                        '2': ['calorex-solar-sl-150'],
                        '3': ['calorex-solar-sl-150'],
                        '4': ['calorex-solar-sl-240'],
                        '5': ['calorex-solar-sl-240'],
                        '6': ['calorex-solar-sl-240'],
                    }
                },
                '2': {
                    'persons': {
                        '1': ['calorex-solar-sl-150'],
                        '2': ['calorex-solar-sl-150'],
                        '3': ['calorex-solar-sl-150'],
                        '4': ['calorex-solar-sl-240'],
                        '5': ['calorex-solar-sl-240'],
                        '6': ['calorex-solar-sl-240'],
                    }
                },
                '3': {
                    'persons': {
                        '1': ['calorex-solar-sl-150'],
                        '2': ['calorex-solar-sl-150'],
                        '3': ['calorex-solar-sl-150'],
                        '4': ['calorex-solar-sl-240'],
                        '5': ['calorex-solar-sl-240'],
                        '6': ['calorex-solar-sl-240'],
                    }
                },
                '4': {
                    'persons': {
                        '1': ['calorex-solar-sl-150'],
                        '2': ['calorex-solar-sl-150'],
                        '3': ['calorex-solar-sl-150'],
                        '4': ['calorex-solar-sl-240'],
                        '5': ['calorex-solar-sl-240'],
                        '6': ['calorex-solar-sl-240'],
                    }
                },
                '5': {
                    'persons': {
                        '1': ['calorex-solar-sl-150'],
                        '2': ['calorex-solar-sl-150'],
                        '3': ['calorex-solar-sl-150'],
                        '4': ['calorex-solar-sl-240'],
                        '5': ['calorex-solar-sl-240'],
                        '6': ['calorex-solar-sl-240'],
                    }
                },
                '6': {
                    'persons': {
                        '1': ['calorex-solar-sl-150'],
                        '2': ['calorex-solar-sl-150'],
                        '3': ['calorex-solar-sl-150'],
                        '4': ['calorex-solar-sl-240'],
                        '5': ['calorex-solar-sl-240'],
                        '6': ['calorex-solar-sl-240'],
                    }
                }
            }
        }
    };
    
    useEffect(()=>{
       // console.log('resultQuery ',resultQuery);
        let versions_by_uid = {};
        //versions indexed by uid 
        for(let i =0;i<resultQuery.edges.length;i++){
            let version = resultQuery.edges[i];
            versions_by_uid[version.node.uid] = version;
        }

        // console.log('hometype',props.hometype)
        // console.log('technologyType',props.technologyType)
        // console.log('services',props.services)
        // console.log('people',props.people)

        // console.log('res',resultMatrix[props.technologyType]);
        // console.log('res',resultMatrix[props.technologyType]['services'][props.services]);
        // console.log('res',resultMatrix[props.technologyType]['services'][props.services]['persons'][props.people]);
        let id_versions = resultMatrix[props.technologyType]['services'][props.services]['persons'][props.people];
        let versions_array = [];        

        id_versions.map((_version_id)=>{
            // console.log('_version_id',_version_id);
            return versions_array.push(versions_by_uid[_version_id]);
        })
        setVersions(versions_array);
        setScrollListenner()
        // eslint-disable-next-line
    },[])
    function setScrollListenner(){
        is_listenning = true;
        window.addEventListener('scroll', ()=>{
            if(scrollButton.current===null)return;
            if(!is_listenning) return;
            let lastScrollY = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
            

            if(lastScrollY>100){
                cssClassManager.add_class(scrollButton.current,'is-hidden');
                
                is_listenning=false;
            }
            
        })
    }
    function get_cover(_version,_key){
        
        return(
            <div key={`result_body--cover${_key}`} className="result_body--cover" >                
                
                <div className="result__image">
                    <img src={_version.node.data.image.url} alt={_version.node.data.image.alt}></img>
                    {/* <Picture
                        sources = {[
                            {
                                srcSet: _version.node.data.result_image.mb.url,
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: _version.node.data.result_image.tb.url,
                                media: "(max-width: 1366px)",
                            },
                            {
                                srcSet: _version.node.data.result_image.lg.url,
                                media: "(min-width: 1919px)",
                            },
                            {
                                srcSet: _version.node.data.result_image.url,
                                media: "(min-width: 1919px)",
                            },
                            ]}
                    /> */}
                </div> 
            </div>
        )
    }
    function get_copy(_version,_key){
        
        return(
            <div key={`result_body--copy${_key}`} className="result_body--copy" >                
                
                <div className="result_body--copycontainer">
                    <p className="result_body--copy_title">{_version.node.data.model.document[0].data.claim.text}</p>
                    <p className="result_body--copy_description">{_version.node.data.model.document[0].data.ideal_copy.text}</p>
                </div> 
            </div>
        )
    }
    function get_uses(_version,_key){        
        return(
            <div key={`result_body--uses${_key}`} className="result_body--uses" >                
                
                <div className="result__ideals">
                    <div className="check__icon">
                        <Check stroke='#000' />
                    </div>
                    <h2>Usos ideales</h2>
                    <ul className='ideals__table'>
                        <li className='ideal__text'> {_version.node.data.model.document[0].data.ideal_list.text} </li>
                    </ul>
                </div>
            </div>
        )
    }
    
    function get_advantages(_version,_key){        
        

        return(
            <div key={`result_body--advantages${_key}`} className="result_body_section result_body--uses" >                
                <div className="result__advantages">
                    <div className="check__icon">
                        <Check stroke='#000' />
                    </div>
                    <h2>Ventajas</h2>
                    <ul className='advantages__table'>
                        {
                            _version.node.data.model.document[0].data.body.map(element => {
                                if(element.__typename === "PrismicModelBodyVentajasResultado"){
                                    return element.items.map((item,_key) => {
                                        
                                        return(
                                            <li key={`advantage-${_key}`} className='advantage__text' dangerouslySetInnerHTML={{__html: item.advantage.html}} />
                                        )
                                    })
                                }else return null
                            })
                        }
                    </ul>
                    
                </div>
                
            </div>
        )
    }
    

    function getMobileTemplate() {
        const settings = {
            customPaging: function(i) {
                return (
                  <span className='pagination__bullet'></span>
                );
            },
            dots: true,
            dotsClass: 'pagination__dots',
            lazyLoad: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <div className={`banner__prev cursor__change`} ></div>,
            prevArrow: <div className={`banner__next cursor__change`} ></div>            
        };

        
        return(
                        
            versions.map((_version,_key)=>{
                
                return(
                    <div key={`result_body_item${_key}`} className="result_body_item">
                        <div className="result_body_header">
                            <div className="result_body_header_container">
                                                        
                                <TextGradient _text={_version.node.data.model.document[0].data.model_name.text} _className='result__header__model' _color_start='#ba0c2f' _color_end='#d90b2d' _deg={180} />
                                <p className='result__version'>{_version.node.data.title.text}</p>                
                            </div>
                        </div>
                        <Slider key={`result_slider_${_key}`} {...settings}>
                            {
                                [
                                    get_cover(_version,_key),
                                    get_copy(_version,_key),
                                    get_uses(_version,_key),
                                    get_advantages(_version,_key)
                                ]
                            }
                        
                        </Slider>
                        <div className="result__links--container">
                            <ButtonArrow _className="result__modelo__btn" _section="result_modelo" _text="Explorar modelo" _to={`/calentadores/${_version.node.data.model.document[0].uid}`} _type={TYPES.NEXT}></ButtonArrow>                           
                        </div>
                    </div>
                    
                )
            })
                     
        )
    }
    function get_scroll_btn(){
        return(
            <div className="result__scroll__btncontainer" ref={scrollButton}>
                <ButtonArrow _className="result__scroll__btn" _section="result_scroll" _onClick={props._backToHelp} _text="Scroll" _position='after' _type={TYPES.DOWN}></ButtonArrow>
            </div>    
        )
         
        
    }
    function getDesktopTemplate() {
        return(
                        
            versions.map((_version,_key)=>{
                
                return(
                    <div key={`result_body_item${_key}`} className="result_body_item">
                        
                        <div className="result_body_section_container">

                        
                            <div className="result_body_left result_body_section">{get_cover(_version,_key)}</div>
                            <div className="result_body_center result_body_section">
                                <div className="result_body_header">
                                    <div className="result_body_header_container">
                                                                
                                        <TextGradient _text={_version.node.data.model.document[0].data.model_name.text} _className='result__header__model' _color_start='#ba0c2f' _color_end='#d90b2d' _deg={180} />
                                        <p className='result__version'>{_version.node.data.title.text}</p>                
                                    </div>
                                </div>
                                
                                {get_copy(_version,_key)}
                                <div className="result__links--container">
                                    <ButtonArrow _className="result__modelo__btn" _section="result_modelo" _text="Explorar modelo" _to={`/calentadores/${_version.node.data.model.document[0].uid}`} _type={TYPES.NEXT}></ButtonArrow>                           
                                </div>
                            </div>
                            <div className="result_body_right result_body_section">
                                {get_uses(_version,_key)}
                                {get_advantages(_version,_key)}
                            </div>
                        </div>                            
                                                
                        
                    </div>
                    
                )
            })
                     
        )
    }
    
    if(resultQuery != null){
        return (            
            <div className="result__container">                
                {
                    ( window.innerWidth >= 1024 && versions.length>1) ? get_scroll_btn() : ''
                }                
                <div className="result__back">
                    <ButtonArrow _className="result__back__btn" _section="result_header" _onClick={props._backToHelp} _text="Iniciar" _position='before' _type={TYPES.PREV}></ButtonArrow>
                </div>
                <ResultHeader slug="Slug" name="Name"
                    title="title" colorStart={ '#ba0c2f'} 
                    colorEnd={ '#d90b2d'}
                /> 
                <div className="result__body">
                {
                    ( window.innerWidth >= 1024 ) ? getDesktopTemplate() : getMobileTemplate() 
                }
                </div>
                <div className="result__footer">
                    <div className="result__footer_container">
                        <div className="detail_maintenance_header_telcontainer">
                            <div className="detail_maintenance_header_telicon">
                                <PhoneRingning></PhoneRingning>
                            </div>
                            <div className="detail_maintenance_header_info_container">
                                <div className="detail_maintenance_header_info_llamanos">Llámanos:</div>
                                <div className="detail_maintenance_header_info_tel">800 CALOREX (225 67 39) </div>
                            </div>
                        </div>
                        <div className="result_footer_claim">Considera que los resultados que se proponen son basados en información básica de tu requerimiento, y pueden variar de acuerdo a condiciones especiales (tamaño del domicilio, distancia entre calentador y regaderas, uso de servicios simultáneos, duración de la ducha, presión de agua, llaves monomando de agua, etc). 
                            Si requieres de mayor asesoría contáctanos mediante el chat en línea.<br/>
                            *Para duchas continuas en calentadores de Depósito, se está considerando un tiempo promedio de recuperación de 15 minutos entre ducha y ducha.<br/>
                            **Para modelos Paso Todo en Uno, si la distancia entre el calentador y la regadera(s) es mayor a 10 m2, tomar el modelo siguiente al recomendado.<br/>
                            ***Para modelos Instantáneos, si la distancia entre el calentador y la regadera(s) es mayor a 7 m2, tomar el modelo siguiente al recomendado. Busca asesoría adicional si utilizas llaves de agua monomando.
                        </div>
                    </div>
                    
                </div>
                            
            </div>
            
        )

    }else{
        return (
            <></>
        )
    }
}

export default Result

